@import '../../node_modules/bootstrap/scss/bootstrap';
@import './mixin';
@import './buttons';

@import 'header.scss';
@import 'main.scss';
@import 'about-us.scss';
@import 'business.scss';
@import 'portfolio.scss';
@import 'contact.scss';
@import 'footer.scss';

h1,
h2,
h3,
p,
span,
li,
address {
  word-break: keep-all;
}

body {
  width: 100%;
  height: 100%;
  color: $defaultColor;
  background-color: black;
}
.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //background: url('../assets/main-image.png') no-repeat center fixed;
  background-image: url('../assets/main-image.png');
  background-size: cover;
  z-index: -1;
}
section {
  min-height: 100vh;
}
h1 {
  font-size: $h1-font-size;
  font-weight: $font-weight-medium;
}
h2 {
  font-size: $h2-font-size;
  font-weight: $font-weight-medium;
  margin-bottom: 50px;
}
h3 {
  font-size: $h3-font-size;
  font-weight: $font-weight-normal;
  line-height: 60px;
}
.fs-40px {
  font-size: 40px !important;
}
.fs-32px {
  font-size: 32px !important;
}
.fs-28px {
  font-size: 28px !important;
}
.fs-26px {
  font-size: 26px !important;
}
.fs-18px {
  font-size: 18px !important;
}
.fs-16px {
  font-size: 16px !important;
}
.px-10px {
  padding: 0 10px;
}
.ml-24px {
  margin-left: 24px;
}
.mb-10px {
  margin-bottom: 10px;
}
img.logo {
  width: 143px;
}
.description {
  font-weight: $font-weight-light;
  letter-spacing: -0.27px;
  line-height: 46.8px;
}
.order {
  flex-direction: row !important;
}
.list-container {
}
.list-item {
  font-size: $description-font-size-2;
  font-weight: $font-weight-light;
  color: $grayColor;
}
.scroll-down {
  color: $defaultColor;
}
.float {
  position: absolute;
  right: 10%;
  bottom: 20%;
  z-index: 1;
}
.scroll-down-vertical {
  transform: rotate(-90deg);
  letter-spacing: -0.41 px;
}
.btn-contact-us {
  color: $defaultColor;
  border: 1px solid $defaultColor;
  &:hover {
    color: $grayColor;
  }
}
.outline-text {
  color: transparent;
  border: $defaultColor;
  -webkit-text-stroke: 1px $defaultColor;
}
.desktop-display-none {
  display: none;
}
.contents {
  width: 80%;
  margin: 0 auto;
}

@media (max-width: $md) {
  h1 {
    font-size: calc($h1-font-size / 1.5);
  }
  h2 {
    font-size: calc($h2-font-size / 1.2);
  }
  h3 {
    font-size: calc($h3-font-size / 1.5);
    line-height: 40px;
  }
  .contents {
    width: 90%;
  }
  p,
  .description {
    font-size: $description-font-size-1 !important;
  }
  .nav-item,
  .list-item {
    font-size: $description-font-size-2 !important;
  }
  .float {
    right: 2%;
    bottom: 10%;
  }
  img.logo {
    width: 100px;
  }
  .background {
    background-position: center;
  }
  .scroll-down {
    margin-bottom: 0;
  }
}

@media (max-width: $sm) {
  .mobile-display-none {
    display: none;
  }
  .desktop-display-none {
    display: block;
  }
  h1 {
    font-size: $h1-mobile-font-size;
    flex-grow: 2;
  }
  h2 {
    font-size: $h2-mobile-font-size;
    margin: 20px 0;
  }
  h3 {
    font-size: $h3-mobile-font-size !important;
    line-height: 36px !important;
  }
  p {
    word-wrap: break-word;
    line-height: initial !important;
  }
  img.logo {
    width: 72px;
  }
  .contents {
    width: 100%;
    margin: 0;
    padding-left: 18px;
    padding-right: 18px;
  }
  .description {
    font-size: 18px !important;
    line-height: 28.8px !important;
  }
  .mobile-float-btn {
    z-index: 100;
    position: fixed;
    right: 18px;
    bottom: 20px;
    &:hover {
      opacity: 0.8;
    }
  }
  .list-container {
    margin: 0 !important;
    padding-left: 20px !important;
  }
  .list-item {
    margin: 3px 0;
    font-size: 14px;
    font-weight: $font-weight-light;
    color: $grayColor;
  }
}
